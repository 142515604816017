"use strict";

import { getCookie, getParameterByName } from '../../util'

(function(){
  function stickyBarCommonCss(){
    return `svg.ba_widget_sticky_bar_close path{fill: #fff;}
    svg.ba_widget_sticky_bar_close{height: 28px;width: 28px;cursor: pointer;position: absolute;height: 30px;top: -11px;right: -11px;overflow: hidden;-webkit-filter: drop-shadow( 0px -1px 3px rgba(0, 0, 0, .4));filter: drop-shadow( 0px -1px 3px rgba(0, 0, 0, .4));}
    .ba_widget_sticky_bar{display: none;z-index: 2247483647;position: fixed;padding: 15px;line-height: 25px;cursor: pointer;text-align: center;font-weight: bold;white-space: nowrap;filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);}
    .ba_widget_sticky_bar.left svg.ba_widget_sticky_bar_close,
    .ba_widget_sticky_bar.bottom_right svg.ba_widget_sticky_bar_close{left: -11px;right: unset;}
    .ba_widget_sticky_bar.bottom_left svg.ba_widget_sticky_bar_close{-webkit-filter: drop-shadow( 1px 0px 3px rgba(0, 0, 0, .4));filter: drop-shadow( 1px 0px 3px rgba(0, 0, 0, .4));}
    .ba_widget_sticky_bar.bottom_right svg.ba_widget_sticky_bar_close{-webkit-filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .4));filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .4));}
    .ba_widget_sticky_bar.left{-webkit-transform: rotate(90deg);-webkit-transform-origin: left bottom;-moz-transform: rotate(90deg);-moz-transform-origin: left bottom;-ms-transform: rotate(90deg);-ms-transform-origin: left bottom;-o-transform: rotate(90deg);-o-transform-origin: left bottom;transform: rotate(90deg);transform-origin: left bottom;left: 0px;bottom: unset;right: unset;}
    .ba_widget_sticky_bar.bottom_left{bottom: 0px;top: unset;right: unset;}
    .ba_widget_sticky_bar.right{filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);-webkit-transform: rotate(270deg);-webkit-transform-origin: 100% 100%;-moz-transform: rotate(270deg);-moz-transform-origin: 100% 100%;-ms-transform: rotate(270deg);-ms-transform-origin: 100% 100%;-o-transform: rotate(270deg);-o-transform-origin: 100% 100%;transform: rotate(270deg);right: 0px;bottom: unset;left: unset;}
    .ba_widget_sticky_bar.bottom_right{bottom: 0px;top: unset;left: unset;}`;
  }

  function appendCommonWidgetCss(usedStickyBar){
    let style = document.createElement("style");
    let stickyBarCss = usedStickyBar ? stickyBarCommonCss() : "";
    style.innerHTML = ".ba-widget-namespace{height: 100%;width: 100%;position: fixed;top: 0;bottom: 0;z-index: 2247483647;}#ba-widget-iframe{border:none;position: absolute;width: 100%;height: 100%;}" + stickyBarCss;
    style.setAttribute("id", "ba-widgets-common");
    document.head.appendChild(style);
  }

  function appendStickyCouponBarCss(config){
    let style = document.createElement("style");
    style.innerHTML = `.ba-sticky-coupon-bar-holder{height: 63px; position: relative;}
      @media (max-width: 550px) {.ba-sticky-coupon-bar-holder{height: 95px;}.ba-sticky-coupon-bar{flex-direction: column;height: fit-content;padding: 20px;gap: 10px;text-align: center;}}
      .ba-sticky-coupon-bar{background: ${config?.colors?.sticky_coupon_bar_bg || "#ffa500"};color: ${config?.colors?.sticky_coupon_bar_text || "#fff"}; font-family: ${config.display_style.font || 'inherit'}; position: fixed;width: 100%;top: 0;z-index: 9999; height: fit-content;padding: 13px 45px;display: flex;align-items: center;justify-content: center;font-size: 16px;box-shadow: 0 2px 2px -1px rgba(0,0,0,0.13);}
      .ba-sticky-coupon-bar-message{line-height: normal;}.ba-stick-coupon-bar-discount-holder{position: relative;display: flex;align-items: center;}
      .ba-sticky-coupon-discount-code-input{height: 37px;margin: 0 0 0 15px;min-width: 215px;padding-left: 15px;border: 0.1rem solid #e9e9e9;background-color: #fff !important;border-radius: 4px;font-family: inherit;color: #9d9d9d;}
      .ba-sticky-coupon-bar-copy-button{background-color: #fff;height: 32px;width: 30px;cursor: pointer;border-radius: 0;border: none;border-left: 1px solid #e9e9e9;position: absolute;right: 5px;top: 3px;min-height: 32px;box-shadow: none;}
      .ba-sticky-coupon-bar-copy:hover::after{opacity: 1}
      .ba-sticky-coupon-bar-copy::after {content: '';background-repeat: no-repeat;left: 3px;opacity: 0.7;top: 0;background-position: 50%;bottom: 0;right: 0;position: absolute; background-size: 21px; background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 24 24'%3E%3Cpath fill='%238d8d8f' d='M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z'/%3E%3C/svg%3E")}
      .ba-sticky-coupon-bar-copy-success:before{content: "";background-color: inherit;width: 30px;height: 33px;border-radius: 4px;z-index: 1;left: 1px;top: -1px;right: 0;bottom: 0;margin: auto;display: block;position: absolute;opacity: 0.7;background-position: 50%;background-size: 39px;background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z' fill='%238d8d8f' fill-rule='non-zero' /%3E%3C/svg%3E")}
      .sticky-coupon-bar-close{top: 20px;right: 20px;position: absolute;z-index: 99;}
      .sticky-coupon-bar-close svg{float: right;fill: #535453;width: 20px;opacity: 0.7;}
      .sticky-coupon-bar-close svg:hover{fill: #d1d1d1;}`
    style.setAttribute("id", `ba-widget-sticky-coupon-bar`);
    document.head.appendChild(style);
  }

  function appendStickyBarCss(widgetId, css){
    let style = document.createElement("style");
    style.innerHTML = css
    style.setAttribute("id", `ba-widget-${widgetId}-stickybar`);
    document.head.appendChild(style);
  }

  function createWidgetIframeNode(widgetId){
    let widgetIframeHtml = `<div class="ba-widgets-messenger-frame" id="ba-widget-wrapper">
      <iframe id="ba-widget-iframe" webkitAllowFullScreen="" mozallowfullscreen="" allowFullScreen="" src="about:blank" sandbox="allow-forms allow-scripts allow-same-origin"></iframe>
    </div>`;

		let node = document.createElement('div');
		node.id = `ba-widget-${widgetId}-container`;
		node.className = "ba-widget-namespace";
		node.innerHTML = widgetIframeHtml;
		node.style.display = "none";
		return node;
  }

  function _init(iframe){
  	let shop = window.Pop ? window.Pop.common.shop : undefined;
		let customer = window.Pop ? window.Pop.common.customer : undefined;
    let widgetCss = window.Pop ? window.Pop.widgets_config.widget_css : undefined;
    let customCss = window.Pop ? window.Pop.widgets_config.custom_css : undefined;
		let requiresPolyfills = !("Promise" in window || "fetch" in window);
		iframe.contentWindow.document.write(`
			<html lang="en">
				<head>
					<title></title>
					${requiresPolyfills ?
						'<script src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=Promise%2Cfetch"></script>' :
						''
					}
          <link rel="stylesheet" href="${window.Pop.global_config.asset_urls.widgets.modal_css}">
					${shop ?
						`<script>window.shop=${JSON.stringify(shop)};</script>` :
						''
					}
					${customer ?
						`<script>window.customer=${JSON.stringify(customer)};</script>` :
						''
					}
          <script src="${window.Pop.global_config.asset_urls.widgets.modal_js}"></script>

          ${window.Pop.widgets_config.initial_state.show_phone_number == "true" ?
            `<link rel="stylesheet" type="text/css" media="all" href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/css/intlTelInput.css">
            <script src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/intlTelInput.min.js"></script>` :
            ''
          }
          <style type="text/css">${widgetCss}</style>
          ${customCss ? `<style type="text/css">${customCss}</style>` : ''}
				</head>
				<body>
          <script>window._init();</script>
        </body>
			</html>
			`);
		iframe.contentWindow.Pop = window.Pop;
    iframe.contentWindow.document.close();
	}

  function setDisplayFrequencyCookie(widget_config){
    let widgetId = widget_config.id;
    let one_minute = 60;
    let one_hour = 3600;
    let one_day = one_hour * 24;
    let one_week = one_day * 7;
    let one_month = one_day * 30;
    let one_year = one_month * 12;
    let cookie_expires_after;
    let frequency_limit_amount = widget_config.frequency_limit_amount || 1;

    switch (widget_config.frequency_limit_time_unit) {
      case "minutes":
        cookie_expires_after = one_minute;
        break;
      case "hours":
        cookie_expires_after = one_hour / frequency_limit_amount;
        break;
      case "days":
        cookie_expires_after = one_day / frequency_limit_amount;
        break;
      case "weeks":
        cookie_expires_after = one_week / frequency_limit_amount;
        break;
      case "months":
        cookie_expires_after = one_month / frequency_limit_amount;
        break;
      case "years":
        cookie_expires_after = one_year / frequency_limit_amount;
        break;
    }
    document.cookie = `ba_widget_${widgetId}_last_shown=true; max-age=${cookie_expires_after}; path=/`;
  }

  function setStickyBarCookie(widgetId, value){
    document.cookie = `ba_widget_${widgetId}_show_sticky_bar=${value}; max-age=${3600 * 24}; path=/`;
  }

  function removeWidgetModal(widgetId){
    document.getElementById(`ba-widget-${widgetId}-container`).remove();
  }

  function closeWidgetModal(widgetId){
    document.getElementById(`ba-widget-${widgetId}-container`).style.display = "none";
  }

  function openWidgetModal(widgetId){
    document.getElementById(`ba-widget-${widgetId}-container`).style.display = "block";
    if (window.Pop.widgets_config.preview_mode != true){
      popMet.register("widget_view", { id: widgetId }, 'pu')
    }
  }

  function showStickyBar(widgetId){
    if(getCookie(`ba_widget_${widgetId}_subscribed`) == "true") return;

    let _stickyBarClose = document.getElementById(`ba_widget_${widgetId}_sticky_bar_close`);
    let _stickyBar = document.getElementById(`ba-widget-${widgetId}-sticky-bar`);

    closeWidgetModal(widgetId);
    _stickyBar.style.display = "block";

    _stickyBarClose.addEventListener("click", function(e) {
      e.stopImmediatePropagation();
      setStickyBarCookie(widgetId, "false");
      _stickyBar.remove();
      removeWidgetModal(widgetId);
    });
    _stickyBar.addEventListener("click", function(e) {
      e.stopImmediatePropagation();
      _stickyBar.style.display = "none";
      openWidgetModal(widgetId);
    });
  }

  function exitPageIntent(widgetId){
    let _html = document.getElementsByTagName("html")[0];
    setTimeout(initExitIntent, 500);

    function initExitIntent() {
      _html.addEventListener("mouseleave", handleMouseleave);
    }

    function handleMouseleave() {
      triggerShowWidget()
    }

    function triggerShowWidget() {
      openWidgetModal(widgetId);
      _html.removeEventListener("mouseleave", handleMouseleave);
    }
  }

  function removeStickyCouponBarCookie(widgetId){
    document.cookie = `ba_widget_${widgetId}_discount_code=; max-age=0; path=/`;
    document.cookie = `ba_widget_${widgetId}_show_sticky_coupon_bar=; max-age=0; path=/`;
  }

  function showStickyCouponBar(widgetId, message){
    let savedDiscountCode = getCookie(`ba_widget_${widgetId}_discount_code`);
    if(savedDiscountCode === null) return;

    let closeCouponBarID = `sticky-coupon-bar-close-${widgetId}`;
    let copyDiscountCodeID = `sticky-coupon-bar-copy-code-${widgetId}`;
    let stickyCouponBar = `<div id="ba-sticky-coupon-widget-${widgetId}" class="ba-sticky-coupon-bar-holder">
      <div class="ba-sticky-coupon-bar">
        <span class="ba-sticky-coupon-bar-message">${message || "Don't forget to use your discount code"}</span>
        <div class="ba-stick-coupon-bar-discount-holder">
          <input type="text" value="${savedDiscountCode}" class="ba-sticky-coupon-discount-code-input">
          <button id="${copyDiscountCodeID}" class="ba-sticky-coupon-bar-copy-button ba-sticky-coupon-bar-copy"></button>
        </div>
        <a href="#" id="${closeCouponBarID}" aria-label="Close" class="sticky-coupon-bar-close">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z" fill="" fill-rule="evenodd"></path></svg>
        </a>
      </div>
    </div>`;

    setTimeout(function(){
      document.body.insertAdjacentHTML("afterbegin", stickyCouponBar);
      // close
      document.getElementById(`${closeCouponBarID}`).addEventListener("click", function(event) {
        event.preventDefault();
        removeStickyCouponBarCookie(widgetId);
        event.currentTarget.closest(".ba-sticky-coupon-bar-holder").remove();
      });

      // copy
      document.getElementById(`${copyDiscountCodeID}`).addEventListener("click", function(event){
        let copyButton = event.currentTarget;
        let discountCodeInput = copyButton.previousElementSibling

        try {
          navigator.clipboard.writeText(discountCodeInput.value);
          copyButton.classList.remove("ba-sticky-coupon-bar-copy");
          copyButton.classList.add("ba-sticky-coupon-bar-copy-success");
          window.setTimeout(function() {
            copyButton.classList.add("ba-sticky-coupon-bar-copy");
            copyButton.classList.remove("ba-sticky-coupon-bar-copy-success");
            discountCodeInput.blur()
          }, 2000);
        } catch (e) {
          console.log(e);
        }
        discountCodeInput.focus();
        discountCodeInput.select();
        setTimeout(function(){
          removeStickyCouponBarCookie(widgetId);
          copyButton.closest(".ba-sticky-coupon-bar-holder").remove();
        }, 1000)
      });
    }, 500)
  }

  window.addEventListener("load",function(){
    const widgetConfig = window.Pop.widgets_config;
    const widgetId = widgetConfig.id;
    const showWidgetStickyBar = getCookie(`ba_widget_${widgetId}_show_sticky_bar`) == "true";
    const usedStickyBar = widgetConfig.closed_state.action == "show_sticky_bar";

    //preview
    const previewMode = getParameterByName("pop_preview") == 1;
    if (previewMode){
      window.Pop.widgets_config.preview_mode = true;
      document.cookie = `ba_widget_${widgetId}_last_shown=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      widgetConfig.active = true;
    }

    // if widget is not published
    if(!widgetConfig.active) return;

    // if pu or pop uninstalled
    const puRegex = "pu_" + window.Pop.common.shop.id;
    const popRegex = "pop_" + window.Pop.common.shop.id;
    const scriptTagPresent = (document.getElementsByTagName('head')[0].innerHTML.search(puRegex + "|" + popRegex) > 0);
    if (!scriptTagPresent){return;}

    // Show Sticky Coupon Bar
    if(widgetConfig.sticky_coupon_bar?.enabled == "true"){
      appendStickyCouponBarCss(widgetConfig);
      showStickyCouponBar(widgetId, widgetConfig.sticky_coupon_bar?.message);
    }

    // if already injected to page
    if(document.querySelector(`#ba-widget-${widgetId}-container`)) return;

    // if widget is not shown within bounds
    if(getCookie(`ba_widget_${widgetId}_last_shown`) == "true" && !showWidgetStickyBar) return;

    // if visitor hash hasnt already been set
    if (!window.Pop.visitor){
      window.Pop.visitor = {
        visitor_token: window.popMet.getVisitorToken(),
        session_token: window.popMet.getVisitToken(),
        browser_info: window.popMet.getBrowserInfo()
      }
      if (previewMode){
        localStorage.setItem("ba_msg_subscriber_" + window.Pop.visitor.visitor_token, "");
      }
      const rawSubscriber    = localStorage.getItem("ba_msg_subscriber_" + window.Pop.visitor.visitor_token);
      const baSubscriberHash = JSON.parse(rawSubscriber || "{}");
      window.Pop.visitor.subscriber_hash = baSubscriberHash;
    }

    // if current visitor is already subscribed to the channels
    let channelsOptedIn = widgetConfig.opt_in_channels.filter(function(channel){
      return parseInt(window.Pop.visitor.subscriber_hash[`ba_${channel}_opted_in`]) > 0
    });

    let availableOptInChannels = widgetConfig.opt_in_channels.filter(channel => !channelsOptedIn.includes(channel));

    // if all channels opted in
    if (availableOptInChannels.length == 0) return;

    // If customer already accepts marketing
    if(window.Pop.common.customer && window.Pop.common.customer.accepts_marketing) return;

    // Widget Display Rules
    if(widgetConfig.rules?.length && !previewMode){
      let rulesChecks = [];
      widgetConfig.rules.forEach(function(rule){
        let urlPathName = window.location.pathname.split("/")[1];
        let urlHref = window.location.href;
        let pageTemplate = window.Pop.common.template;
        switch(rule.operator) {
          case "equal":
            urlPathName == rule.value ? rulesChecks.push(true) : rulesChecks.push(false)
            break;
          case "not_equal":
            urlPathName == rule.value ? rulesChecks.push(false) : rulesChecks.push(true)
            break;
          case "contains":
            urlHref.includes(rule.value) ? rulesChecks.push(true) : rulesChecks.push(false)
            break;
          case "not_contains":
            urlHref.includes(rule.value) ? rulesChecks.push(false) : rulesChecks.push(true)
            break;
          case "is_homepage":
            pageTemplate == "index" ? rulesChecks.push(true) : rulesChecks.push(false)
            break;
          case "not_homepage":
            pageTemplate != "index" ? rulesChecks.push(true) : rulesChecks.push(false)
            break;
        }
      });
      if(!rulesChecks.includes(true)) return
    }

    let node = createWidgetIframeNode(widgetId);
    let rpoints = {
      container: node.querySelector("#ba-widget-wrapper"),
      iframe: node.querySelector("#ba-widget-iframe"),
    };
    document.body.appendChild(node);
    _init(rpoints.iframe);

    appendCommonWidgetCss(usedStickyBar);

    if(usedStickyBar){
      let stickyBarOffset = widgetConfig.closed_state.display_offset || 10;
      let stickyBarCss = `
        #ba-widget-${widgetId}-sticky-bar{color: ${widgetConfig.colors.sticky_bar_text || "#fff"};background: ${widgetConfig.colors.sticky_bar_bg || "#286ef8"};font-family: ${widgetConfig.display_style.font || "Arial"};font-size: ${widgetConfig.closed_state.font_size || 20}px;}
        #ba-widget-${widgetId}-sticky-bar.left{top: ${stickyBarOffset}px;}
        #ba-widget-${widgetId}-sticky-bar.bottom_left{left: ${stickyBarOffset}px;}
        #ba-widget-${widgetId}-sticky-bar.right{top: ${stickyBarOffset}px;}
        #ba-widget-${widgetId}-sticky-bar.bottom_right{right: ${stickyBarOffset}px;}
        ${widgetConfig.custom_css}`;

      appendStickyBarCss(widgetId, stickyBarCss);

      let widgetStickyBar = `<div id="ba-widget-${widgetId}-sticky-bar" class="ba_widget_sticky_bar live ${widgetConfig.closed_state.display_position}" style="${showWidgetStickyBar ? 'display:block' : ''}">
        <svg id="ba_widget_${widgetId}_sticky_bar_close" class="ba_widget_sticky_bar_close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
          <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M19.707,18.293 c0.391,0.391,0.391,1.023,0,1.414C19.512,19.902,19.256,20,19,20s-0.512-0.098-0.707-0.293L15,16.414l-3.293,3.293 C11.512,19.902,11.256,20,11,20s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L13.586,15l-3.293-3.293 c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L15,13.586l3.293-3.293c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414 L16.414,15L19.707,18.293z"><path>
        </svg>
        <div>${widgetConfig.closed_state.action_text}</div>
      </div>`;
      document.getElementById(`ba-widget-${widgetId}-container`).insertAdjacentHTML('beforebegin', widgetStickyBar);
    }

    // Show Sticky Bar
    if(showWidgetStickyBar && usedStickyBar){
      showStickyBar(widgetId);
    }

    window.addEventListener('message', function(event){
      try{
        const msg = JSON.parse(event.data)
        switch(msg.action){
          case "ba.widget.show_widget_modal":
            openWidgetModal(msg.widget_id);
            setDisplayFrequencyCookie(widgetConfig);
            break;
          case "ba.widget.init_exit_page_intent":
            exitPageIntent(msg.widget_id);
            break;
          case "ba.widget.remove_modal":
            removeWidgetModal(msg.widget_id);
            break;
          case "ba.widget.show_sticky_bar":
            showStickyBar(msg.widget_id);
            setStickyBarCookie(msg.widget_id, "true");
            break;
          case "ba.widget.set_subscribed_cookie":
            document.cookie = `ba_widget_${msg.widget_id}_subscribed=true; max-age=${3600 * 24}; path=/`;
            if(usedStickyBar) setStickyBarCookie(msg.widget_id, "false");
            break;
          case "ba.widget.redirect_url":
            window.location = widgetConfig.success_state.redirect_url
            break;
          case "ba.widget.go_to_url":
            Object.assign(document.createElement("a"), { target: "_blank", href: msg.url }).click();
            break;
          case "ba.widget.save_discount_code":
            document.cookie = `ba_widget_${msg.widget_id}_discount_code=${msg.code}; max-age=${3600 * 24}; path=/`;
            break;
          case "ba.widget.show_sticky_coupon_bar":
            document.cookie = `ba_widget_${msg.widget_id}_show_sticky_coupon_bar=true; max-age=${3600 * 24}; path=/`;
            showStickyCouponBar(msg.widget_id, msg.message);
            break;
        }
      } catch(e){
        // slide
      }
    });
  });
})();
